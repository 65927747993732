import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import Authorizer from './Authorizer';
import { RevokeHandler } from './components/RevokeHandler/RevokeHandler';
import { LogoutCallbackHandler } from './components/LogoutCallbackHandler/LogoutCallbackHandler';

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/authorize" component={Authorizer} />
        <Route exact path="/revoke" component={RevokeHandler} />
        <Route
          exact
          path="/callback"
          component={LogoutCallbackHandler}
        />
        <Route path="/" component={Authorizer} />
      </Switch>
    </Router>
  );
}

export default App;
