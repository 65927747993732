import React from 'react';
import config from '../../config';
import { environment } from '../../helpers/services';
import logo from '../../assets/images/wordmark.svg';
const CochlearHomePage = config.CochlearDotCom[environment];

const NavbarBasic = () => {
  return (
    <nav
      className="navbar"
      role="navigation"
      aria-label="main navigation"
    >
      <div className="navbar-brand">
        <a href={CochlearHomePage} className="navbar-item">
          <img src={logo} alt="Cochlear" />
        </a>
      </div>
    </nav>
  );
};

export default NavbarBasic;
