// config

const config = {
  env: process.env.REACT_APP_DRX_ENV,
  cookieDomain: {
    LOCAL: '',
    DEV: '.cochlear.cloud',
    SIT: '.cochlear.cloud',
    UAT: '.cochlear.com',
    PRD: '.cochlear.com',
  },
  drxMain: {
    LOCAL: 'https://dmdev.cx.nonp.cochlear.cloud',
    DEV: 'https://dmdev.cx.nonp.cochlear.cloud',
    SIT: 'https://dmsit.cx.nonp.cochlear.cloud',
    UAT: 'https://www2.cochlear.com',
    PRD: 'https://www.cochlear.com',
  },
  store: {
    LOCAL: 'http://localhost/',
    DEV: 'https://node.dev.cms.cochlear.cloud/',
    SIT: 'https://node.sit.cms.cochlear.cloud/',
    UAT: 'https://www2.cochlear.com/',
    PRD: 'https://www.cochlear.com/',
  },
  profStore: {
    LOCAL: 'http://localhost/fixme-add-prof-store-link',
    DEV:
      'https://node.dev.cms.cochlear.cloud/fixme-add-prof-store-link',
    SIT:
      'https://node.sit.cms.cochlear.cloud/fixme-add-prof-store-link',
    UAT: 'https://www2.cochlear.com/de/de/professionals/shop/home',
    PRD: 'https://www.cochlear.com/de/de/professionals/shop/home',
  },
  deviceSupport: {
    LOCAL: 'http://localhost',
    DEV: 'https://dev-drx.cx.nonp.cochlear.cloud/',
    SIT: 'https://sit-drx.cx.nonp.cochlear.cloud/',
    UAT: 'https://support-uat.cochlear.com/',
    PRD: 'https://support.cochlear.com/',
  },
  CochlearDotCom: {
    LOCAL: 'http://localhost/',
    DEV: 'https://dev-drx.cx.nonp.cochlear.cloud/',
    SIT: 'https://node.sit.cms.cochlear.cloud/',
    UAT: 'https://www2.cochlear.com/',
    PRD: 'https://www.cochlear.com/',
  },
  gql: {
    LOCAL: {
      scCd: 'dmuat.cx.nonp.cochlear.cloud',
      apiKey: '%7BC8D77F78-6799-48EC-A2D9-0D4A9C0E34C1%7D',
      authToken: 'ZGV2czpncmVlbmF2b2NhZG8=',
      countryLanguageList: '',
    },
    DEV: {
      scCd: 'dmuat.cx.nonp.cochlear.cloud',
      apiKey: '%7BC8D77F78-6799-48EC-A2D9-0D4A9C0E34C1%7D',
      authToken: 'ZGV2czpncmVlbmF2b2NhZG8=',
      countryLanguageList: '',
    },
    SIT: {
      scCd: 'dmsit.cx.nonp.cochlear.cloud',
      apiKey: '%7BC8D77F78-6799-48EC-A2D9-0D4A9C0E34C1%7D',
      authToken: 'ZGV2czpHcmVhdEMwY2hsZWFy',
      countryLanguageList: '',
    },
    UAT: {
      scCd: 'www2.cochlear.com',
      apiKey: '%7BC8D77F78-6799-48EC-A2D9-0D4A9C0E34C1%7D',
      authToken: 'ZGV2czpncmVlbmF2b2NhZG8=',
      countryLanguageList: '',
    },
    PRD: {
      scCd: 'www.cochlear.com',
      apiKey: '%7BC373F5B9-AE6C-4E7B-B746-EEACB3D8EA0C%7D',
      authToken: '',
      countryLanguageList: '',
    },
  },
  authenticate: {
    LOCAL: {
      authorize: 'http://localhost:3003/drx/v1/auth/authorize',
      token: 'http://localhost:3003/drx/v1/auth/token',
      revoke: 'http://localhost:3003/drx/v1/auth/revoke',
      cimLogout:
        'https://cochlear--uob2.sandbox.my.site.com/Recipient/CIM_RecipientLogout',
    },
    DEV: {
      authorize: 'https://dev.api.cochlear.com/drx/v1/auth/authorize',
      token: 'https://dev.api.cochlear.com/drx/v1/auth/token',
      revoke: 'https://dev.api.cochlear.com/drx/v1/auth/revoke',
      cimLogout:
        'https://cochlear--uob2.sandbox.my.site.com/Recipient/CIM_RecipientLogout',
    },
    SIT: {
      authorize: 'https://sit.api.cochlear.com/drx/v1/auth/authorize',
      token: 'https://sit.api.cochlear.com/drx/v1/auth/token',
      revoke: 'https://sit.api.cochlear.com/drx/v1/auth/revoke',
      cimLogout:
        'https://cochlear--sit01.sandbox.my.site.com/Recipient/CIM_RecipientLogout',
    },
    UAT: {
      authorize: 'https://uat.api.cochlear.com/drx/v1/auth/authorize',
      token: 'https://uat.api.cochlear.com/drx/v1/auth/token',
      revoke: 'https://uat.api.cochlear.com/drx/v1/auth/revoke',
      cimLogout:
        'https://cochlear--uat.sandbox.my.site.com/Recipient/CIM_RecipientLogout',
    },
    PRD: {
      authorize: 'https://api.cochlear.com/drx/v1/auth/authorize',
      token: 'https://api.cochlear.com/drx/v1/auth/token',
      revoke: 'https://api.cochlear.com/drx/v1/auth/revoke',
      cimLogout:
        'https://cochlear.my.site.com/Recipient/CIM_RecipientLogout',
    },
  },
  auth0: {
    LOCAL: {
      customDomain: 'https://poc.id.cochlear.com',
      host: 'https://cochlear-poc.au.auth0.com',
      clientId: 'genWNPWs3VwRNhIUX43v8rw9SW93qFrq',
      logoutRedirectUrl:
        'https://authnz-dev.cx.nonp.cochlear.cloud/callback',
    },
    DEV: {
      customDomain: 'https://dev.id.cochlear.com',
      host: 'https://cochlear-dev.eu.auth0.com',
      clientId: 'j7JQUr4JeH7p16eLQAXIZ1diTrj1Z60u',
      logoutRedirectUrl:
        'https://authnz-dev.cx.nonp.cochlear.cloud/callback',
    },
    SIT: {
      customDomain: 'https://sit.id.cochlear.com',
      host: 'https://cochlear-sit.eu.auth0.com',
      clientId: 'fPARxTBd5XUM8T4i2Xhk8RMTPdABdqZg',
      logoutRedirectUrl:
        'https://authnz-sit.cx.nonp.cochlear.cloud/callback',
    },
    UAT: {
      customDomain: 'https://uat.id.cochlear.com',
      host: 'https://cochlear-uat.eu.auth0.com',
      clientId: '5WOxA6WfyBh1h2mFlJlbqAtdzpUUwKuR',
      logoutRedirectUrl: 'https://id-uat.cochlear.com/callback',
    },
    PRD: {
      customDomain: 'https://id.cochlear.com',
      host: 'https://cochlear.eu.auth0.com',
      clientId: 'pMveXXshi8UAKcRWLotFBj739ISp9lI8',
      logoutRedirectUrl: 'https://id-prd.cochlear.com/callback',
    },
  },
};

export default config;
