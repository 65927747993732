import React from 'react';
import config from '../../config';
import { environment } from '../../helpers/services';
import Cookies from 'js-cookie';

const cochlearDotCom = config.CochlearDotCom[environment];
const domainValue = config.cookieDomain[environment];

export const LogoutCallbackHandler = () => {
  // If there is no redirect URL cookie, then redirect to the main website
  let redirectUrl;

  try {
    redirectUrl = Cookies.get('referrerUrl');
    // Cookies.remove('referrerUrl');
    // The line has been added, as the one above did not clear the Cookie
    Cookies.remove('referrerUrl', { domain: domainValue });
    // console.log(`Post logout url: ${Cookies.get('referrerUrl')}`);
  } catch (e) {
    console.log('Failed to retrieve the referrerUrl', e);
  }
  const url = redirectUrl || cochlearDotCom;
  // console.log(`Handling logout redirect from auth0 - redirecting to: ${url}`)
  window.location.replace(url);
  return <></>;
};
