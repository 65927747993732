import React from 'react';
import {
  environment,
  authenticationService,
} from '../../helpers/services';
import getMinutes from '../../helpers/utilities/getMinutes';
import config from '../../config';
import Cookies from 'js-cookie';

/* 
This component runs when accessing the /revoke url:

https://authnz-sit.cx.nonp.cochlear.cloud/revoke

We set the referral url to the drx authorize api (it will generate the login url and do a redirect):

https://sit.api.cochlear.com/drx/v1/auth/authorize

After logout Auth0 redirects to https://authnz-sit.cx.nonp.cochlear.cloud/callback - this is one of the allowed returnTo urls:
 
const auth0LogoutUrl = `${auth0CustomDomain}/v2/logout?client_id=${auth0ClientId}&returnTo=${auth0LogoutRedirectUrl}`;

The LogoutCallbackHandler component runs when accessing the /callback url, and here the referralUrl can be used
to ensure that the user is returned to the login page.
*/
export const RevokeHandler = () => {
  const domainValue = config.cookieDomain[environment];
  const url = config.authenticate[environment].authorize;

  try {
    Cookies.set('referrerUrl', url, {
      domain: domainValue,
      secure: true,
      expires: getMinutes(120),
    });

    authenticationService.logout();
  } catch (e) {
    console.log('Failed to remove the referrerUrl or logout', e);
  }
  return <></>;
};
