import axios from 'axios';
import Cookies from 'js-cookie';

import config from '../../config';
import { environment } from './environment';
import getMinutes from '../utilities/getMinutes';

const { authenticate, cookieDomain, auth0 } = config;

const tokenUrl = authenticate[environment].token;
const authUrl = authenticate[environment].authorize;
const revokeUrl = authenticate[environment].revoke;
const domainValue = cookieDomain[environment];
const auth0ClientId = auth0[environment].clientId;
const auth0LogoutRedirectUrl = auth0[environment].logoutRedirectUrl;
const auth0CustomDomain = auth0[environment].customDomain;

function logout() {
  // eslint-disable-next-line no-use-before-define
  const auth0LogoutUrl = `${auth0CustomDomain}/v2/logout?client_id=${auth0ClientId}&returnTo=${auth0LogoutRedirectUrl}`;
  const token = authenticationService.currentUserValue;

  if (token) {
    // Blacklist the user's session ID
    axios
      .post(`${revokeUrl}`, { token })
      .then((response) => {})
      .catch((error) => {
        // TODO Ideally this error should be handled
      })
      .then(() => {
        // Remove the user's token cookies
        clearLoggedInUser();
        // Redirect to auth0 logout
        window.location.replace(auth0LogoutUrl);
      });
  } else {
    // The user doesn't have a token, so just redirect to auth0's logout
    window.location.replace(auth0LogoutUrl);
  }
}

// renew the users token
function renew() {
  // clear app session
  clearLoggedInUser();
  // eslint-disable-next-line no-undef
  window.location.replace(authUrl);
  // redirect them to the authorizer url
  return null;
}

function clearLoggedInUser() {
  // console.log('Remove current user and accessToken from cookie in auth-application')
  Cookies.remove('currentUser', {
    domain: domainValue,
  });
  Cookies.remove('accessToken', {
    domain: domainValue,
  });
}

function login(code) {
  return axios
    .post(tokenUrl, { code })
    .then((response) => {
      // console.log('login -> response', response);
      if (response.status !== 200) {
        if ([401, 403].indexOf(response.status) !== -1) {
          // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
          // eslint-disable-next-line no-use-before-define
          authenticationService.logout();
        }
        const error = response.message;
        return Promise.reject(error);
      }
      return response.data;
    })
    .then((user) => {
      const {
        idToken,
        // remove accessToken until it can be stored responsibly without exceeding cookie limits
        // accessToken,
        attributes,
      } = user;

      // store user details and jwt token in local storage to keep
      // user logged in between page refreshes
      Cookies.set('currentUser', idToken, {
        domain: domainValue,
        secure: true,
        expires: getMinutes(120),
      });

      // store additional user attributes
      Cookies.set('currentUserAttributes', attributes, {
        domain: domainValue,
        secure: true,
        expires: getMinutes(120),
      });

      return idToken;
    });
}

// eslint-disable-next-line import/prefer-default-export
export const authenticationService = {
  login,
  logout,
  renew,
  updateCurrentUser(user) {
    Cookies.set('currentUser', user, {
      domain: domainValue,
      secure: true,
      expires: getMinutes(120),
    });
  },
  get currentUserValue() {
    return Cookies.get('currentUser');
  },
};
