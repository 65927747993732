import React from 'react';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import DOMPurify from 'dompurify';
import config from './config';
import {
  authenticationService,
  environment,
} from './helpers/services';

const {
  deviceSupport,
  CochlearDotCom,
  // authenticate
} = config;

// const { cimLogout } = authenticate[environment];
const CochlearHomePage = CochlearDotCom[environment];
const SupportHomePage = deviceSupport[environment];

function getImgProperties(str) {
  const newDiv = document.createElement('div');
  newDiv.innerHTML = str;
  return {
    src: newDiv.getElementsByTagName('img')[0].src,
    width: newDiv.getElementsByTagName('img')[0].width,
    alt: newDiv.getElementsByTagName('img')[0].alt,
  };
}

class AuthorizationError extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleLogout = this.handleLogout.bind(this);
    this.handleBack = this.handleBack.bind(this);
  }

  handleLogout() {
    authenticationService.logout();
    // call logout service and get a response.
    // authenticationService
    //   .logout()
    //   .then((value) => {
    //     console.log('success: ', value);
    //   })
    //   .catch((error) => {
    //     console.log('error: ', error);
    //   })
    //   .finally(() => {
    //     console.log('logging out user');
    //     const goToPage = this.getBackUrl();
    //     // eslint-disable-next-line no-undef
    //     window.location.replace(`${cimLogout}?logoutURL=${goToPage}`);
    //   });
  }

  handleBack() {
    const goToPage = this.getBackUrl();
    // eslint-disable-next-line no-undef
    window.location.replace(goToPage);
  }

  // eslint-disable-next-line class-methods-use-this
  getBackUrl() {
    const { refHomePage } = this.props;
    // Gets the cookie that was stored when the user clicked on login or raise service request
    const getReferrerUrl = Cookies.get('referrerUrl');
    const getGoBackUrl = Cookies.get('goBackUrl'); // only gets set when loggin in via ds so may not always exist

    // Remove cookies as it's no longer needed
    // We dont want it to stay here anymore
    Cookies.remove('referrerUrl');
    Cookies.remove('goBackUrl');

    // what about a referrer that is set to the service request route?
    // if the referrer is for sr, get the go back url cookie if it exists
    // if they are getting this message it means they dont have access for sr so we dont want to refer them back there
    if (getReferrerUrl) {
      if (getReferrerUrl.includes('service-request')) {
        // we want to get the go back url
        if (getGoBackUrl) {
          return getGoBackUrl;
        }
        return `${SupportHomePage}us/en/home`;
      }
      return getReferrerUrl;
    }
    return refHomePage;
  }

  render() {
    const {
      errorData,
      errorType,
      errorResponse,
      labels,
    } = this.props;

    const { image, title, text } = errorData;
    const imageValue = image.value;
    // object values for the image
    const getImg = getImgProperties(imageValue);

    return (
      <div className="error-message error-message--authorizer">
        <div className="error-message__content">
          <figure className="error-message__image">
            <img
              src={getImg.src}
              alt={getImg.alt}
              style={{ width: getImg.width }}
            />
          </figure>
          <h2 className="error-message__title">{title.value}</h2>
          <p
            className="error-message__text"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(text.value),
            }}
          />

          {/* display error code */}
          {errorType === 'networkError' && errorResponse && (
            <p className="error-message__text error-message__text--code">
              <b>{`${labels.codeLabel}:`}</b>
              &nbsp;
              {errorResponse}
            </p>
          )}
        </div>

        {/* #1 - any user / no app claim - < Back */}
        {/* #2 - provisional / no app claim - < Back */}
        {/* #4 - r/c / no app claim - < Back */}
        {/* #6 - provisional / no app claim - < Back */}
        {/* #9 - any user / no app claim - < Back */}
        {/* #11 - provisional / no app claim - < Back */}
        {/* Default - Network Error - Cochlear home > */}

        <div className="error-message__button-control">
          {errorType !== 'networkError' ? (
            <button
              type="button"
              onClick={this.handleLogout}
              className="button button--primary button--primary--yellow has-shift-transition has-icon-left"
            >
              <i className="drxi drxi--chevron--left" />
              <span>{labels.backLabel}</span>
            </button>
          ) : (
            <a
              href={CochlearHomePage}
              className="button button--primary button--primary--yellow has-shift-transition has-icon-left"
            >
              <i className="drxi drxi--chevron--left" />
              <span>{labels.homeLabel}</span>
            </a>
          )}
        </div>
      </div>
    );
  }
}

export default AuthorizationError;

AuthorizationError.propTypes = {
  errorData: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  errorType: PropTypes.string.isRequired,
  errorResponse: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  labels: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
    .isRequired,
  refHomePage: PropTypes.string.isRequired,
};

// Specifies the default values for props:
AuthorizationError.defaultProps = {
  errorResponse: 'Network error',
};
